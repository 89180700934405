.account-page {
    display: flex;
    height: 100vh;
  }
  
  .side-nav {
    width: 250px;
    background-color: #333;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .side-nav-link {
    color: white;
    text-decoration: none;
    margin-bottom: 20px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .side-nav-link:hover {
    color: #FFC0CB; /* Light pink */
  }
  
  .logout-btn {
    background: none;
    border: none;
    color: white;
    text-align: left;
    padding: 0;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
  }
  
  .profile-section, .orders-section {
    margin-bottom: 40px;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #FFC0CB; /* Light pink */
    color: #ADD8E6; /* Light blue */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #ADD8E6; /* Light blue */
    color: #FFC0CB; /* Light pink */
  }
  