.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #add8e6, #ffdab9);
  }
  
  .signin-form {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .signin-form h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #666;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn-group {
    margin-top: 20px;
  }
  
  .signin-btn {
    background-color: #ff69b4;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .signin-btn:hover {
    background-color: #ff1493;
  }
  
  .signin-footer {
    margin-top: 20px;
  }
  
  .signin-footer p {
    margin: 5px 0;
  }
  
  .signin-footer a {
    color: #ff69b4;
    text-decoration: none;
  }