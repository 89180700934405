.navbar {
    background: linear-gradient(to right, #ADD8E6, #FFC0CB); /* Blue to pink gradient */
    padding: 15px 0;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .navbar-brand,
  .navbar-link,
  .username {
    color: white; /* White lettering */
    text-decoration: none;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Black outline */
  }
  
  .navbar-brand {
    font-size: 1.5rem;
  }
  
  .navbar-toggle {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .navbar-menu {
    list-style-type: none;
    display: flex;
  }
  
  .navbar-item {
    margin-right: 20px;
  }
  
  .navbar-icons {
    display: flex;
    align-items: center;
  }
  
  .navbar-icon {
    margin-right: 20px;
    font-size: 1.5rem;
    position: relative;
  }
  
  .cart-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    display: none;
  }
  
  .cart-icon:hover .cart-dropdown {
    display: block;
  }
  
  .cart-count,
  .username {
    position: relative;
  }
  
  .cart-count {
    background-color: #FFC0CB; /* Light pink */
    color: #ADD8E6; /* Light blue */
    border-radius: 50%;
    padding: 5px;
    font-size: 0.8rem;
    top: -10px;
    right: -10px;
  }
  
  .user-dropdown {
    position: relative;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ADD8E6; /* Light blue */
    min-width: 120px;
    padding: 10px;
    border-radius: 5px;
    right: 0;
    top: 30px;
  }

  .cart-dropdown.open {
    display: block;
  }

  .dropdown-content.open {
    display: block;
  }
  
  .user-dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content p,
  .dropdown-content button {
    margin: 5px 0;
    color: #FFC0CB; /* Light pink */
  }
  
  button {
    background-color: transparent;
    border: none;
    color: #FFC0CB; /* Light pink */
    cursor: pointer;
    padding: 0;
    font-size: inherit;
  }
  